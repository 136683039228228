import { useParseData } from './parse.utils'
import { consola } from 'consola'

export const useGenerateUrl = (settings: any) => {
    const { getTransItemMulti} = useParseData()

    const routes = settings.routes || []

    const generateUrlByNameRoute = (name: string, options?: any) => {
        try {
            if (name.endsWith('_test') && settings.isDev) {
                name = name.split('_test')[0]
            }
            let route = routes.find((item: any) => item.name === name)

            if (!route) throw new Error('Not find route !')
            return generateExpectedRoute(route.path, options)
        } catch (e: any) {
            consola.error('ERR  generateUrlByNameRoute: ', e.message)
            consola.debug(e)
        }
    }

    const generateUrlSearchFilter = (collection: string, languageCode: string, options?: any) => {
        try {
            let params = new URLSearchParams(options).toString()

            return `/api/search/${collection}?lang=${languageCode}${options ? '&' + params : ''}`
        } catch (e: any) {
            consola.error('ERR generateUrlFilter: ', e.message)
            consola.debug(e)
        }
    }

    const generateExpectedRoute = (routeRegex: any, params: any) => {
        let expectedRoute = routeRegex

        for (const paramKey in params) {
            const paramValue = params[paramKey]
            const paramRegex = new RegExp(':' + paramKey + '\\b', 'g')
            expectedRoute = expectedRoute.replace(paramRegex, paramValue)
        }

        const nonParamRegex = /\([^)]*\)|\[[^\]]*\]|[^-\w\d\/\-]/g
        expectedRoute = expectedRoute.replace(nonParamRegex, '')

        return expectedRoute
    }
    return {
        generateUrlByNameRoute,
        generateUrlSearchFilter
    }
}
